import { Auth } from "aws-amplify";
import { BroadcastChannel } from "broadcast-channel";
import { loaderActions } from "../";
import { LoaderContent } from "../../../utility/constants/LoaderContent";
import {clinicActions} from "../";
import {profilePicActions} from "./"
import {platformCoreActions} from "../"

export const IHP_CHANNEL = new BroadcastChannel('ihealth-portal');
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_EMAIL_VERIFIED = "SET_USER_EMAIL_VERIFIED";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";
export const RESET_USER_ON_SIGNOUT = "RESET_USER_ON_SIGNOUT";
export const SET_JWT_EXP = "SET_JWT_EXP";
export const USER_SESSION_EXP = "USER_SESSION_EXP";
export const USER_SESSION_UPDATED = "USER_SESSION_UPDATED";
export const LOGIN_CHALLENGES = {SMS_MFA:'SMS_MFA',NEW_PASSWORD_REQUIRED:'NEW_PASSWORD_REQUIRED'}
export const CHANNEL_EVENTS = {
  SIGN_OUT:'sign-out',
  SIGN_IN:'sign-in',
  SESSION_EXPIRED:'session-expired',
  SESSION_REFRESHED:'session-refreshed'
};
export const SET_USER_ATTRIBUTES = "SET_USER_ATTRIBUTES";
export const SET_USER_FORCE_PASSWORD_CHANGE = "SET_USER_FORCE_PASSWORD_CHANGE";
export const SET_USER_CHALLENGE = "SET_USER_CHALLENGE";
export const RESET_LOGIN = "RESET_LOGIN";
export const appContext = {uid:`${Date.now()}_${Math.random()*1000}`,user:null};
/* IHP_CHANNEL.onmessage = (message)=>{
  console.log('BroadcastChannel',appContext.uid,message);
  switch(message.event){
    case CHANNEL_EVENTS.SIGN_IN: !appContext.user && window.location.reload();break;
    case CHANNEL_EVENTS.SIGN_OUT: appContext.user && window.location.reload();break;
    //case CHANNEL_EVENTS.SESSION_EXPIRED: break;

  }
} */
const setUserLoggedIn = isLoggedin => ({ type: SET_USER_LOGGED_IN, payload: isLoggedin });
const getLastAT = ()=> localStorage.getItem('la_time') || Date.now();
const updateTokenAccessTime = (reset=false)=>{
  const latime = reset?getLastAT():Date.now()
  localStorage.setItem('la_time',latime);
  return latime;
};
export const setUserEmail = email => ({ type: SET_USER_EMAIL, payload: email });

//const setUserEmailVerified = isEmailVerified => ({ type: SET_USER_EMAIL_VERIFIED, payload: isEmailVerified });

const setAppInitialized = isInitialized => ({ type: SET_APP_INITIALIZED, payload: isInitialized });

const resetUserOnSignout = (payload) => ({ type: RESET_USER_ON_SIGNOUT,payload });

const setJWTExp = exp => ({ type: SET_JWT_EXP, payload: exp });

const setForcePasswordChange = isForcePasswordChange => ({ type: SET_USER_FORCE_PASSWORD_CHANGE, payload: isForcePasswordChange });

// Function to get the User Role Associated with the Clinic Id
const getUserRole = (currentUser) => {
  let userRole = {}
  try {
    if(currentUser.signInUserSession.idToken.payload.tenant_role) {
      userRole = JSON.parse(currentUser.signInUserSession.idToken.payload.tenant_role);
    }
  } catch(error) {
    userRole = {}
  }
  return userRole;
}

export const fetchAuthenticatedUser = (forceCheck) => async (dispatch, getState) => {
  let sessionData = {};
  try{
    let bypassCache = false;
    const init=!appContext.user
    const { exp } = getState().userReducer.jwt;
    const currentEpochInUTC = getCurrentEpoch();
    
    if (!exp || currentEpochInUTC >= Number(exp)) {
      bypassCache = true;
    }
    //console.log('fetchAuthenticatedUser',exp,appContext.user);
    const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
    sessionData = {email: currentUser.attributes.email};
    const newExp = currentUser.signInUserSession.idToken.payload.exp;
    resetUserContext(currentUser.attributes.sub);
    const userRole = getUserRole(currentUser);
    const inactiveTimeoutInSec = parseInt(currentUser.signInUserSession.idToken.payload.inactive_timeout || '60000');
    const lastATime = getLastAT();
    const lastATimediff = (Date.now() - lastATime)/1000;
    const expired = lastATimediff>=inactiveTimeoutInSec;
    const sessionWasAE = forceCheck && expired
    if(!sessionWasAE && init){
      dispatch({type:USER_SESSION_UPDATED,payload:{expired:false,email: currentUser.attributes.email,lat:Date.now(),expiry:inactiveTimeoutInSec*1000}});
    }
    else{ 
      if(expired){
        //console.log('inactiveTimeoutInSec',inactiveTimeoutInSec,lastATimediff,getLastAT());
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_EXPIRED,uid:appContext.uid,data:{sessionData}});
        //throw new Error(CHANNEL_EVENTS.SESSION_EXPIRED,'session Expired');
        expirySession(dispatch,sessionWasAE);
      }
      else{
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_REFRESHED,uid:appContext.uid,data:{lat:Date.now()}});
        dispatch(refreshSession());
      }
  }
    dispatch(setJWTExp(newExp));
    updateTokenAccessTime();
    //console.log('inactiveTimeoutInSec',lastATime,inactiveTimeoutInSec,lastATimediff);
    return {
      email: currentUser.attributes.email,
      isEmailVerified: currentUser.attributes.email_verified,
      firstName: currentUser.attributes.given_name, 
      lastName: currentUser.attributes.family_name,
      phoneNumber: currentUser.attributes.phone_number,
      address: currentUser.attributes.address,
      token: currentUser.signInUserSession.idToken.jwtToken,
      isFirstLogin: currentUser.attributes['custom:is_first_time_login']?currentUser.attributes['custom:is_first_time_login']:"1",
      userRole,
    };
  }
  catch(error){
    console.log('token error',error);
    //assume that refresh token expired
    if(appContext.user){
      IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_EXPIRED,uid:appContext.uid,data:{sessionData}});
      expirySession(dispatch);
    }
    throw {response:{error,status:401}};
  }
  
};

export const renewToken = async dispatch => {
  try{
    await dispatch(fetchAuthenticatedUser());
  }catch(error){
  }
} 

export const initializeApplication = async dispatch => {
  try {
    
    // Check for the User Preference for RememberMe Selection
    if(localStorage.rememberMe === 'false') {
      dispatch(setAppInitialized(true));
      return;  
    }

    const userAttributes = await dispatch(fetchAuthenticatedUser(true));
    dispatch(setUserAttributes({...userAttributes, isLoggedIn:true}));
    dispatch(setAppInitialized(true));

    dispatch(profilePicActions.fetchUserPermissions());
    dispatch(clinicActions.fetchUserClinics());
    dispatch(profilePicActions.fetchProfilePic());
    dispatch(platformCoreActions.fetchAnnouncements());

  } catch (error) {
    dispatch(setAppInitialized(true));
    updateTokenAccessTime(true);
  }
};

const loginSucess = loginResponse => async dispatch => {

  const userRole = getUserRole(loginResponse);

  const userAttributes = {
    isLoggedIn: true,
    challenge:null,
    email: loginResponse.attributes.email,
    isEmailVerified: loginResponse.attributes.email_verified,
    firstName: loginResponse.attributes.given_name, 
    lastName: loginResponse.attributes.family_name,
    phoneNumber: loginResponse.attributes.phone_number,
    address: loginResponse.attributes.address,
    isFirstLogin: loginResponse.attributes['custom:is_first_time_login']?loginResponse.attributes['custom:is_first_time_login']:"1",
    userRole,
  }

  return await Promise.all([
    dispatch(setUserAttributes(userAttributes)),
    dispatch(profilePicActions.fetchUserPermissions()),
    dispatch(clinicActions.fetchUserClinics()),
    dispatch(profilePicActions.fetchProfilePic()),
    dispatch(platformCoreActions.fetchAnnouncements()),
    dispatch(loaderActions.stopAppLoader()),
  ]);
};

export const handleUserNotConfirmed = email => async dispatch => {
  await Auth.resendSignUp(email);
  return await dispatch(resetUserOnSignout({email,userNotConfirmed:true}));
};

const handleForceChangePassword = (email, forcePasswordChangeFlag, loggedInFlag) => async dispatch => {
  return await Promise.all([dispatch(setForcePasswordChange(forcePasswordChangeFlag)), dispatch(setUserLoggedIn(loggedInFlag)), dispatch(setUserEmail(email))]);
};

const handleChallenge = (email, challenge,loggedInFlag) => async dispatch => {
  return await Promise.all([dispatch({ type: SET_USER_CHALLENGE, payload: challenge }), dispatch(setUserEmail(email))]);
};

const resetUserContext = (user=null)=>appContext.user = user

export const login = (email, password) => async dispatch => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    dispatch(resetUserOnSignout({}));
    resetUserContext();//make sure user context reseted
    const loginResponse = await Auth.signIn(email, password);
    updateTokenAccessTime();
    // Check for the Change Password Challenge
    if(loginResponse.challengeName && LOGIN_CHALLENGES[loginResponse.challengeName]) {
      if(loginResponse.challengeName === LOGIN_CHALLENGES.NEW_PASSWORD_REQUIRED) {
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleForceChangePassword(email, true, true));
      }else{
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleChallenge(email, {[loginResponse.challengeName]:loginResponse}, true));
      }
    }
    IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_IN,uid:appContext.uid});
    await dispatch(loginSucess(loginResponse));
    return loginResponse;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const resetLogin = () => async dispatch =>{
  dispatch({type:RESET_LOGIN});
}

export const verifyOtp = (user,otp) => async dispatch => {
  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    //const user = await Auth.currentUserPoolUser();
    updateTokenAccessTime();
    // Check for the Change Password Challenge
    if(otp && user){
        await Auth.confirmSignIn(user,otp,LOGIN_CHALLENGES.SMS_MFA);
        const currentUser = await Auth.currentAuthenticatedUser();
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_IN,uid:appContext.uid});
        await dispatch(loginSucess(currentUser));
        return currentUser;
    }
    return user;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const signout = async dispatch => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await onSignout(dispatch);
  dispatch(loaderActions.stopAppLoader());
};

export const clearPrvSession = async dispatch => {
  dispatch(resetUserOnSignout({}));
};

export const expirySession = async (dispatch,inForce=false) =>{
  dispatch({type:USER_SESSION_UPDATED,payload:{expired:true,inForce}});
}
export const refreshSession =(lat)=> async dispatch =>{
  dispatch({type:USER_SESSION_UPDATED,payload:{lat:lat||Date.now()}});
}
export const logout = (sessionData={}) => async dispatch => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await onSignout(dispatch,sessionData);
  dispatch(loaderActions.stopAppLoader());
};

const onSignout = async (dispatch,sessionData={manual:true})=>{
  updateTokenAccessTime(true);
  resetUserContext();
  await Auth.signOut();
  await dispatch(resetUserOnSignout({sessionData}));
  dispatch(clinicActions.resetUserClinics());
  IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_OUT,uid:appContext.uid});
  resetUserContext();
}

// ********************************
// New functions are added
// ********************************

const getCurrentAuthenticatedUser = () => async (dispatch, getState) => {
  let bypassCache = false;

  const { exp } = getState().userReducer.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  if (!exp || currentEpochInUTC >= Number(exp)) {
    bypassCache = true;
  }

  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache });
  const newExp = currentUser.signInUserSession.idToken.payload.exp;
  dispatch(setJWTExp(newExp));
  return currentUser;
};

export const setUserAttributes = (userAttributes) => dispatch => {
  dispatch({ type: SET_USER_ATTRIBUTES, 
    payload:  userAttributes
  });
}

export const updateUserAttributes = (given_name, family_name, phone_number, address) => async dispatch => {

  dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PROFILE));
  //const user = await Auth.currentAuthenticatedUser({ bypassCache });
  const user = await dispatch(getCurrentAuthenticatedUser());

   await Auth.updateUserAttributes(user, {given_name, family_name, phone_number, address} )
  .then(_res => {
    dispatch(setUserAttributes({ 
      firstName:  given_name, 
      lastName: family_name, 
      phoneNumber: phone_number,
      address,
    }))
    dispatch(loaderActions.stopAppLoader())
  })
  .catch(err => {
    dispatch(loaderActions.stopAppLoader())
    throw err;
  })

}

export const updateFirstTimeUserAttribute = (isFirstLogin) => async dispatch => {
  const user = await dispatch(getCurrentAuthenticatedUser());
   await Auth.updateUserAttributes(user, {"custom:is_first_time_login": isFirstLogin} )
  .then(_res => {
    dispatch(setUserAttributes({ 
      isFirstLogin,
    }))
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPassword = (email) => dispatch => {
  return Auth.forgotPassword(email)
  .then(() => {
    dispatch(setUserEmail(email));
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPasswordSubmit = (email, code, password) => dispatch => {
  return Auth.forgotPasswordSubmit(email, code, password)
  .then(() => {
    dispatch(setUserEmail(email));
  })
  .catch(err => {
    throw err;
  })
};

export const changePasswordSubmit = (email, oldPassword, password) => async dispatch => {

  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.CHANGE_PASSWORD));

    const user = await Auth.signIn(email, oldPassword);

    // Check for the Change Password Challenge
    if(user.challengeName) {
      if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const requiredAttributes = {}
        await Auth.completeNewPassword(user, password, requiredAttributes);        
      }
    } else {
      await Auth.changePassword(user, oldPassword, password);
    }

    dispatch(loaderActions.stopAppLoader())
    return await dispatch(handleForceChangePassword(email, false, false));

  } catch(error) {
    dispatch(loaderActions.stopAppLoader())
    throw error;
  }

};

// *****************************************
// Generic Function - TODO - Move it to Utility Functions later
// *****************************************

const getCurrentUTCEpoch = () => {
  var currentDate = new Date();
  var currentUTCDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
  var currentUTCEpoch = Math.floor(currentUTCDate.getTime() / 1000);
  return currentUTCEpoch;
};

const getCurrentEpoch = () => {
  var currentUTCEpoch = Math.floor(Date.now() / 1000);
  return currentUTCEpoch;
};



